import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { LayoutContainer } from '@d24/modules/front';
// import { LayoutContainer, LayoutSlot } from '@d24/modules/front'
import { PageBuilder } from '@component/PageBuilder';
import { Paywall } from '../Paywall';
import { WithLayout } from '../WithLayout';
/**
 * Component capable of rendering the page's contents.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let Page = class Page extends WithLayout {
    constructor() {
        super(...arguments);
        this.LayoutContainer = LayoutContainer;
        // public get slotMain () {
        //   const main = this.slots[LayoutSlot.Main]
        //   if (main) {
        //     // main.modules = main.modules?.filter((module) =>
        //     //   ['paywall', 'ad_banner', 'pagination', 'articles_list'].indexOf(module.driver) === -1)
        //     // console.warn(main.modules)
        //     return main
        //   }
        //   return []
        // }
        this.mainContentTag = 'main';
    }
};
Page = __decorate([
    Component({
        name: 'Page',
        components: {
            PageBuilder,
            Paywall
        }
    })
], Page);
export { Page };
export default Page;
