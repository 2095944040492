import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { PageBuilder } from '@component/PageBuilder';
import { WithLayout } from '../WithLayout';
/**
 * Component capable of rendering the landing page composed with modules.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Landing = class Landing extends WithLayout {
};
Landing = __decorate([
    Component({
        name: 'Landing',
        components: { PageBuilder }
    })
], Landing);
export { Landing };
export default Landing;
